import { useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { observer } from 'mobx-react-lite';
import xw from 'xwind';
import { isMobile } from 'react-device-detect';

import { ProductStore, WishlistStore } from '../../stores';
import Spinner from '../../components/Spinner';
import { ProductCardView } from '../../components/product';
import {
  getPrice,
  getSymbolFromCurrency,
  getTransformedImageSrc,
} from '../../utils';

interface ProductCardProps {
  product: any;
  defaultCollectionTitle?: string;
}

const ProductCard = ({
  product,
  defaultCollectionTitle,
}: ProductCardProps): JSX.Element => {
  const router = useRouter();
  const [inProcessingAddToWishlist, setInProcessingAddToWishlist] = useState(
    false,
  );

  const { title, image, variants, vendor, collections } = product ?? {};
  const firstVariant = variants?.[0];
  const isExistInWishlist = WishlistStore.checkAlreadyAdded(firstVariant?.id);
  const currency = getSymbolFromCurrency();
  const productPrice = `${currency}${getPrice(firstVariant.price)}`;
  const imageSrc = getTransformedImageSrc(image?.src, '_500x');
  const collectionTitle = collections?.title ?? defaultCollectionTitle;

  function navigateToProductPage() {
    if (product) {
      router.push(`/products/${product.slug}`);
    }
  }

  async function addToWishlist(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    try {
      event.stopPropagation();
      setInProcessingAddToWishlist(true);

      const productId = product?.id;
      const variantId = product?.variants?.[0]?.id;

      await WishlistStore.addProduct(productId, variantId, product.type);
    } catch (error) {
      //
    } finally {
      setInProcessingAddToWishlist(false);
    }
  }

  function renderAddToWishlistButton() {
    const disabled = isExistInWishlist || inProcessingAddToWishlist;
    const buttonText = isExistInWishlist
      ? 'Added to WishList'
      : 'Add to WishList';
    const buttonChildren = inProcessingAddToWishlist ? <Spinner /> : buttonText;

    return (
      <ProductCardView.AddToWishlistButton
        disabled={disabled}
        onClick={addToWishlist}
      >
        {buttonChildren}
      </ProductCardView.AddToWishlistButton>
    );
  }

  return (
    <ProductCardView>
      <div className="group">
        <ProductCardView.MediaContainer onClick={navigateToProductPage}>
          {imageSrc && (
            <Image
              layout="fill"
              objectFit="contain"
              src={imageSrc}
              alt={title}
              unoptimized
            />
          )}
          <div
            css={[
              xw`w-full`,
              isMobile
                ? xw`flex items-end`
                : xw`hidden group-hover[flex items-end]`,
            ]}
          >
            {renderAddToWishlistButton()}
          </div>
        </ProductCardView.MediaContainer>
      </div>
      <ProductCardView.Content>
        <ProductCardView.Vendor>{vendor}</ProductCardView.Vendor>
        <ProductCardView.Title role="button" onClick={navigateToProductPage}>
          {title}
        </ProductCardView.Title>
        <ProductCardView.Price>{productPrice}</ProductCardView.Price>
      </ProductCardView.Content>
    </ProductCardView>
  );
};

ProductCard.defaultProps = {
  defaultCollectionTitle: '',
};

export default observer(ProductCard);
