import { DEFAULT_VARINAT_OPTION_VALUE } from '@/services/shopify';

function getSelectedOptions(options, variant) {
  const { option1, option2, option3 } = variant || {};
  const selectedOptions = [];
  [option1, option2, option3].forEach((value, index) => {
    if (value && value !== DEFAULT_VARINAT_OPTION_VALUE) {
      selectedOptions.push({
        name: options?.[index]?.name,
        value,
      });
    }
  });

  return selectedOptions;
}

export default getSelectedOptions;
