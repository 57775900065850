import { MouseEventHandler } from 'react';
import { observer } from 'mobx-react-lite';

import AuthStore from '@/stores/AuthStore';
import AuthModalStore from '@/stores/AuthModalStore';

import LoadingIndicator from '@/components/LoadingIndicator';
import { getSelectedOptions } from '@/domains/Wishlist/utils';
import useAddToCart from '@/hooks/useAddToCart';
import { useAddProductVariant } from '@/widgets/product/hooks/useAddProductVariant';
import { StyledAddToCartButton, CartIconContainer } from './ProductTile.styles';
import CartIcon from './CartIcon';

interface AddToCartButtonProps {
  product: any;
  villageId: string;
}

function AddToCartButton({
  product,
  villageId,
}: AddToCartButtonProps): JSX.Element {
  const [inProcessing, addToCart] = useAddToCart();

  const { variants, contentType, type, options } = product ?? {};
  const productType = contentType || type;

  const addCallback = (variantId: string) => {
    addToCart(variantId, 1, [
      villageId && {
        key: '_village_slug',
        value: villageId,
      },
      {
        key: '_user_email',
        value: AuthStore.user?.email,
      },
      {
        key: '_variant_options',
        value: JSON.stringify(
          getSelectedOptions(
            options,
            variants?.find((variant) => variant?.id === variantId),
          ),
        ),
      },
    ]);
  };

  const [AddModal, showAddModal] = useAddProductVariant({
    productSlug: product.slug,
    modalTitle: 'Add to cart',
    addCallback,
    buttonAddText: 'Add',
    toCart: true,
  });

  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    if (AuthStore.isLoggedIn) {
      if (product.variants.length > 1 || product.type !== 'product') {
        showAddModal();
      } else {
        addCallback(product.variants[0].id);
      }
    } else {
      AuthModalStore.showModal('login');
    }
  };

  return (
    productType === 'product' && (
      <>
        <StyledAddToCartButton
          inProcessing={inProcessing}
          onClick={handleClick}
        >
          {inProcessing ? (
            <LoadingIndicator />
          ) : (
            <CartIconContainer>
              <CartIcon />
            </CartIconContainer>
          )}
        </StyledAddToCartButton>
        {AddModal}
      </>
    )
  );
}

export default observer(AddToCartButton);
