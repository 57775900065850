import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Skeleton, Stack } from '@mui/material';

import { Product } from '@/types/Content/Product/Product';

import { Select as CustomSelect } from '@/components/Forms/Select/Select';
import { FormModalContent } from '@/widgets/Modal/FormModal/FormModal.styles';
import xw from 'xwind';

export interface IProductAddForm {
  initialValues: Record<string, string>;
  handleSubmit: (values: any) => void;
  loading: boolean;
  product: Product;
}

const ProductAddForm: React.FC<IProductAddForm> = ({
  initialValues,
  handleSubmit,
  loading,
  product,
}) => {
  return !loading ? (product?.options?.filter((option) => option.name != 'Title')?.length ? (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Stack spacing={3} component={Form}>
          <FormModalContent>
            <Stack spacing={3}>
              {product?.options.map(({ name, values }) => (
                <Field key={name} name={name}>
                  {({ field }) => (
                    <CustomSelect
                      label={name}
                      name={name}
                      options={values}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        submitForm();
                      }}
                    />
                  )}
                </Field>
              ))}
            </Stack>
          </FormModalContent>
        </Stack>
      )}
    </Formik>) : (<></>)
  ) : (
    <>
      <FormModalContent>
        <Stack spacing={3}>
          <Skeleton
            variant="rectangular"
            height={44}
            width="100%"
            style={xw`rounded-lg`}
          />
        </Stack>
      </FormModalContent>
    </>
  );
};

export default ProductAddForm;
