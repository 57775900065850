import { StyledComponent } from '@emotion/styled';
import { styled } from '@mui/material';
import { HTMLAttributes } from 'react';

export const listTileStyles = {
  title: '',
};

export const Container: StyledComponent<
  { $loading: boolean; bgColor: string },
  HTMLAttributes<HTMLDivElement>,
  any
> = styled('div')<{ $loading?: boolean; bgColor: string }>`
  position: relative;
  padding-top: ${(7 / 6) * 100}%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ bgColor }) => bgColor};
  overflow: hidden;
`;

export const Content = styled('div')`
  display: flex;
  align-items: end;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: ${({ theme }) => theme.spacing(4)};
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1875) 59.37%,
    rgba(0, 0, 0, 0.75) 100%
  );
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(5)};
  }
`;

export const IconContainer = styled('div')`
  position: absolute;
  top: ${({ theme }) => theme.spacing(3.5)};
  right: ${({ theme }) => theme.spacing(3.5)};
  height: ${({ theme }) => theme.spacing(6)};
  width: ${({ theme }) => theme.spacing(6)};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: ${({ theme }) => theme.spacing(8)};
    width: ${({ theme }) => theme.spacing(8)};
  }
`;

export const TitleContainer = styled('div')`
  width: 100%;
`;

export const Title = styled('h2')`
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[50]};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ theme }) => theme.typography.h4.fontSize};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
  }
`;

export const Subtitle = styled('p')`
  width: 100%;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  color: ${({ theme }) => theme.palette.grey[600]};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ theme }) => theme.typography.h4.fontSize};
  }
`;
