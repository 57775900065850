import { HTMLAttributes, ReactNode } from 'react';
import { color } from 'theme.material';

import {
  Container,
  Content,
  IconContainer,
  TitleContainer,
  Title,
  Subtitle,
} from './ListTile.styles';

interface ListTitleProps extends Omit<HTMLAttributes<HTMLDivElement>, 'css'> {
  children?: ReactNode;
  loading?: boolean;
  bgColor?: string;
}
function ListTile({
  children,
  loading,
  ...props
}: ListTitleProps): JSX.Element {
  return (
    <Container $loading={loading} {...props}>
      {children}
    </Container>
  );
}

ListTile.defaultProps = {
  loading: false,
  children: null,
  bgColor: color.grey[100],
};

ListTile.Container = Container;
ListTile.Content = Content;
ListTile.IconContainer = IconContainer;
ListTile.TitleContainer = TitleContainer;
ListTile.Title = Title;
ListTile.Subtitle = Subtitle;

export default ListTile;
