import { useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import toast from 'react-hot-toast';
import xw from 'xwind';

import encodeStorefrontNodeId from '@/utils/encodeStorefrontNodeId';
import { useCartContext } from '@/services/shopify';

const useAddToCart = (): any => {
  const [inProcessingAddToCart, setInProcessingAddToCart] = useState(false);
  const { addToCart: performAddToCart } = useCartContext();

  async function addToCart(
    currentVariantId,
    quantity = 1,
    customAttributes = null,
  ) {
    try {
      setInProcessingAddToCart(true);

      if (currentVariantId) {
        const variantId = encodeStorefrontNodeId(
          'ProductVariant',
          currentVariantId,
        );
        await performAddToCart({ variantId, quantity, customAttributes });
        toast('This item has been added successfully!', {
          duration: 3000,
          style: xw`px-16 text-active`,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setInProcessingAddToCart(false);
    }
  }

  return [inProcessingAddToCart, addToCart];
};

export default useAddToCart;
