import { useState, useRef, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';
import xw from 'xwind';

import * as Sentry from '@sentry/nextjs';
import WishlistStore from '@/stores/WishlistStore';
import Icon from '@/components/icons/Icon';
import VillagePageStore from '@/stores/VillagePageStore';
import { useAddProductVariant } from '@/widgets/product/hooks/useAddProductVariant';
import { Product } from '@/types/Content/Product/Product';

interface ManageWishlistButtonProps {
  product: Product;
  villageId?: string;
}

function ManageWishlistButton({
  product,
  villageId,
}: ManageWishlistButtonProps): JSX.Element {
  const inProcessingRef = useRef(false);

  const { shopifyId, id, contentId } = product ?? {};
  const mergedId = shopifyId || id || contentId;
  const type = product?.type || product?.contentType;
  const { wishlistContent } = VillagePageStore;
  const wishlistProductItem = useMemo(
    () => wishlistContent.find((item) => item.id === product.id),
    [product.id, wishlistContent],
  );
  const [checked, setChecked] = useState(!!wishlistProductItem);

  const addCallback = async (variantId) => {
    if (inProcessingRef.current) return;

    try {
      inProcessingRef.current = true;
      setChecked((prev) => !prev);
      if (wishlistProductItem) {
        await VillagePageStore.removeWishlistItem({
          productId: wishlistProductItem.djangoId,
        });
      } else {
        await WishlistStore.addProduct(mergedId, variantId, type, villageId);
        VillagePageStore.fetchWishlist();
      }
    } catch (error) {
      Sentry.captureException(error);
      setChecked((prev) => !prev);
    }

    inProcessingRef.current = false;
  };

  const [AddModal, showAddModal] = useAddProductVariant({
    productSlug: product.slug,
    modalTitle: 'Add to wishlist',
    addCallback,
    buttonAddText: 'Add',
  });

  useEffect(() => {
    if (!!wishlistProductItem !== checked && !inProcessingRef.current) {
      setChecked(!!wishlistProductItem);
    }
  }, [checked, wishlistProductItem]);

  async function handleClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (!wishlistProductItem && product.variants.length > 1) {
      showAddModal();
    } else {
      addCallback(product.variants[0].id);
    }
  }

  return (
    <>
      <Button onClick={handleClick} css={xw`min-w-0 text-xl p-2 leading-none`}>
        <Icon iconName={checked ? 'heart-filled' : 'heart'} />
      </Button>
      {AddModal}
    </>
  );
}

ManageWishlistButton.defaultProps = {
  villageId: undefined,
};

export default observer(ManageWishlistButton);
