import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Skeleton } from '@mui/material';
import Link from 'next/link';
import Image from 'next/image';

import { Variant } from '@/types/Content/Product/Variant';
import { Product } from '@/types/Content/Product/Product';

import * as Sentry from '@sentry/nextjs';
import productService from '@/services/productService';
import FormModal from '@/widgets/Modal/FormModal';
import ProductAddForm from '@/widgets/product/ProductAddForm';
import { FormModalActions } from '@/widgets/Modal/FormModal/FormModal.styles';
import xw from 'xwind';

export type TUseAddProductVariant = {
  productSlug: string;
  modalTitle: string;
  addCallback: (variantId: string) => void;
  buttonAddText?: string;
  toCart?: boolean;
};

export const useAddProductVariant = ({
  productSlug,
  modalTitle,
  addCallback,
  buttonAddText,
  toCart = false,
}: TUseAddProductVariant): [JSX.Element, () => void] => {
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<Variant | null>(null);

  const selectedAffiliate = useMemo(() => {
    return (
      product?.affiliatedLinks?.length &&
      (product?.affiliatedLinks.filter(
        (item) => item?.sku === selectedVariant?.sku,
      ) || [product?.affiliatedLinks[0]])
    );
  }, [product?.affiliatedLinks, selectedVariant?.sku]);

  const initialValues = useMemo(() => {
    const values: Record<string, string> = {};
    product?.options.forEach((option) => {
      if (option.name && option.values[0]) {
        // eslint-disable-next-line prefer-destructuring
        values[option.name] = option.values[0];
      }
    });
    return values;
  }, [product]);

  const toggleOpen = () => {
    setIsOpen((val) => {
      if (val) {
        setProduct(null);
        setLoading(false);
      }
      return !val;
    });
  };

  const handleOpen = useCallback(() => {
    const getProduct = async () => {
      try {
        setLoading(true);
        const data = await productService.getBySlug(productSlug);
        if (data) {
          setProduct(data);
        }
        return;
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoading(false);
      }
    };
    toggleOpen();
    getProduct();
  }, [productSlug]);

  const handleAdd = useCallback(
    (variantId: string) => {
      addCallback(variantId);
      setIsOpen(false);
    },
    [addCallback],
  );

  const handleSubmit = useCallback(
    (values) => {
      const newVariant = product?.variants.find((variant) => {
        return product?.options.every((option, i) => {
          if (values[option.name]) {
            return (
              (variant[`option${i + 1}`] || variant.options[i]) ===
              values[option.name]
            );
          }
          return false;
        });
      });
      setSelectedVariant(newVariant);
    },
    [product?.options, product?.variants],
  );

  const handleAddVariant = useCallback(() => {
    handleAdd(selectedVariant?.id);
  }, [handleAdd, selectedVariant?.id]);

  useEffect(() => {
    if (isOpen && product) {
      handleSubmit(initialValues);
    }
  }, [handleSubmit, initialValues, isOpen, product]);

  const modal = useMemo(
    () =>
      isOpen ? (
        <FormModal title={modalTitle} open={isOpen} onClose={toggleOpen}>
          <ProductAddForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            loading={loading}
            product={product}
          />
          {!loading ? (
            <FormModalActions>
              <Button type="reset" onClick={toggleOpen}>
                Cancel
              </Button>
              {toCart &&
                selectedAffiliate?.length > 0 &&
                selectedAffiliate?.map((link) => (
                  <>
                    <Link href={link.url} passHref>
                      <Button
                        component="a"
                        target="_blank"
                        variant="contained"
                        endIcon={
                          <Image width={20} height={20} src={link.iconUrl} />
                        }
                        onClick={toggleOpen}
                      >
                        {link.name}
                      </Button>
                    </Link>
                  </>
                ))}
              {!(toCart && product?.type !== 'product') && (
                <Button onClick={handleAddVariant} variant="contained">
                  {buttonAddText || 'Add'}
                </Button>
              )}
            </FormModalActions>
          ) : (
            <FormModalActions>
              <Skeleton
                variant="rectangular"
                height={44}
                width={89}
                style={xw`rounded-full`}
              />
              <Skeleton
                variant="rectangular"
                height={44}
                width={67.5}
                style={xw`rounded-full`}
              />
            </FormModalActions>
          )}
        </FormModal>
      ) : (
        <></>
      ),
    [
      buttonAddText,
      handleAddVariant,
      handleSubmit,
      initialValues,
      isOpen,
      loading,
      modalTitle,
      product,
      selectedAffiliate,
      toCart,
    ],
  );
  return [modal, handleOpen];
};
