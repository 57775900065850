import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import Link from 'next/link';
import Image from 'next/image';
import { observer } from 'mobx-react-lite';

import ListTile from '@/components/ListTile';
import getPrice from '@/utils/getPrice';
import getSymbolFromCurrency from '@/utils/getSymbolFromCurrency';
import { ContentType } from '@/common/constants/ContentTypes';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import { Product } from '@/types/Content/Product/Product';
import {
  ImageWrapper,
  ImageContainer,
  Vendor,
  Price,
  Content,
  HeaderContainer,
  TitleContainer,
  Title,
} from './ProductTile.styles';
import ManageWishlistButton from './ManageWishlistButton';
import AddToCartButton from './AddToCartButton';

export interface ProductTileProps {
  product: Product;
  defaultCollectionTitle?: string;
  villageId?: string;
  className?: string;
  bgColor?: string;
}
function ActionButton({ product, villageId, isAdmin }) {
  if (!isAdmin) {
    return <AddToCartButton product={product} villageId={villageId} />;
  }

  return <ManageWishlistButton product={product} villageId={villageId} />;
}
const ProductTile = ({
  product,
  villageId,
  bgColor,
  className,
}: ProductTileProps): JSX.Element => {
  const { isAdmin } = FridgeDoorStore;

  const [width, setWidth] = useState(0);
  const tileRef = useRef<HTMLDivElement>(null);

  const { title, image, vendor, variants, affiliatedLinks } = product ?? {};
  const availableVariant = variants?.find((variant) => variant.isAvailable);
  const availableAffiliate = affiliatedLinks?.find((link) => link.url);

  let productPrice = 'Out Of Stock';
  let inStock = false;
  if (availableVariant) {
    inStock = true;
    productPrice = `${getSymbolFromCurrency()}${getPrice(availableVariant?.price)}`
  } else if (availableAffiliate) {
    inStock = true;
    productPrice = `${getSymbolFromCurrency()}${getPrice(availableAffiliate?.price)}`
  }

  let imageSrc = image?.url || '';

  useEffect(() => {
    const handleResize = throttle(() => {
      if (tileRef?.current) {
        setWidth(Math.round(tileRef.current.getBoundingClientRect().width));
      }
    });
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [tileRef]);

  return (
    <ListTile bgColor={bgColor} className={className}>
      <Content ref={tileRef}>
        <HeaderContainer>
          <Link href={`/${ContentType.PRODUCTS}/${product.slug}`} passHref>
            <Price>{productPrice}</Price>
          </Link>
          {inStock &&
            <ActionButton
              product={product}
              villageId={villageId}
              isAdmin={isAdmin}
            />
          }
        </HeaderContainer>
        <ImageWrapper>
          <Link href={`/${ContentType.PRODUCTS}/${product.slug}`} passHref>
            <ImageContainer>
              {imageSrc && (
                <Image
                  layout="fill"
                  objectFit="contain"
                  src={imageSrc}
                  alt={title}
                  unoptimized
                  role="button"
                />
              )}
            </ImageContainer>
          </Link>
        </ImageWrapper>
        <Link href={`/${ContentType.PRODUCTS}/${product.slug}`} passHref>
          <TitleContainer>
            {vendor && <Vendor title={vendor?.name}>{vendor?.name}</Vendor>}
            <Title width={width} title={title} role="button">
              {title}
            </Title>
          </TitleContainer>
        </Link>
      </Content>
    </ListTile>
  );
};

ProductTile.defaultProps = {
  defaultCollectionTitle: '',
  villageId: undefined,
  className: null,
};

export default observer(ProductTile);
