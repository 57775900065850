function CartIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.667 1.75h2.041m0 0L5.21 8.817a1.055 1.055 0 001.06.85h5.155a1.065 1.065 0 001.06-.85l.85-4.428-9.054.055-.572-2.694zM6.44 12.305a.53.53 0 01-1.06 0c0-.291.237-.527.53-.527.293 0 .53.236.53.527zm5.834 0a.53.53 0 01-1.06 0c0-.291.237-.527.53-.527.292 0 .53.236.53.527z"
        stroke="currentColor"
        strokeWidth={1.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CartIcon;
