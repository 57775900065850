import xw from 'xwind';
import { css } from '@emotion/react';
import { styled } from '@mui/material';

import ListTile from '@/components/ListTile';

interface PlusButtonProps {
  added: boolean;
  inProcessing?: boolean;
}

const buttonStyle = xw`
  w-6 h-6 sm[w-8 h-8]
  border-2 border-primary rounded-full
  bg-none transition duration-500
  focus:outline-none overflow-hidden
`;

export const ImageWrapper = styled('div')(
  xw`w-full flex-1 flex justify-center items-center items-center px-5`,
);

export const ImageContainer = styled('div')(xw`relative h-full w-full`);

export const Vendor = styled('h3')(
  xw`text-xs text-primary sm[text-sm mb-1] truncate`,
);

export const Price = styled('a')(xw`text-sm sm:text-xl text-black font-bold`);

export const StyledAddToCartButton = styled('button')(
  ({ inProcessing }: { inProcessing: boolean }) => [
    buttonStyle,
    xw`
      flex justify-center items-center text-primary
      hover[bg-primary text-white]`,
    inProcessing && xw`bg-white hover:bg-white`,
  ],
);

export const CartIconContainer = styled('div')(
  xw`w-3 h-3`,
  css`
    padding-right: 0.5px;
    @media (min-width: 640px) {
      width: 15px;
      height: 15px;
      padding-right: 0;
      margin-right: 1px;
    }
  `,
);

export const PlusButton = styled('button')(({ added }: PlusButtonProps) => [
  buttonStyle,
  css`${added && xw`bg-primary transform rotate-45`}}`,
]);

export const PlusIcon = styled('div')`
  ${xw`w-full h-full flex justify-center items-center`}

  &:before {
    content: '';
    ${xw`absolute w-3.5 h-0.5 bg-primary transition`}
    background-color: ${({ added }: PlusButtonProps) => added && xw`bg-white`}
  }

  &:after {
    content: '';
    ${xw`absolute w-0.5 h-3.5 bg-primary transition`}
    background-color: ${({ added }: PlusButtonProps) => added && xw`bg-white`}
  }
`;

export const Content = styled(ListTile.Content)`
  display: flex;
  background: none;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(3.5, 0)};
  }
`;
export const HeaderContainer = styled('div')`
  display: flex;
  flex: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(0, 4, 0, 5)};
  }
`;

export const TitleContainer = styled('div')(xw`
  w-full mt-3 px-5 flex-none
`);

export const Title = styled(ListTile.Title)<{ width: number }>`
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.text.primary};
  height: ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: ${({ theme }) => theme.spacing(7)};
  }
`;
