import xw from 'xwind';
import styled from '@emotion/styled';

export const StyledProductCard = styled.article(xw`bg-white overflow-hidden`);

export const MediaContainer = styled.div(
  xw`
    relative bg-white cursor-pointer
    w-full aspect-w-1 aspect-h-1 
    object-cover object-center focus:outline-none`,
);

export const AddToWishlistButton = styled.button(
  xw`
    w-full h-12 flex justify-center items-center
    bg-primary bg-opacity-80 text-white
    transition hover:bg-opacity-100 focus:outline-none`,
);

export const Content = styled.div(xw`pt-4 pr-4`);

export const Vendor = styled.h3(xw`text-sm sm:text-base text-gray-500 mb-3`);

export const Title = styled.h2(
  xw`text-lg sm:text-xl text-gray-900 mb-3 focus:outline-none`,
);

export const Price = styled.p(xw`text-lg sm:text-xl text-gray-900 font-bold`);
