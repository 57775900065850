import {
  StyledProductCard,
  MediaContainer,
  Content,
  AddToWishlistButton,
  Vendor,
  Title,
  Price,
} from './ProductCardView.styles';

function ProductCardView({
  children,
  ...props
}: Omit<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>,
  'css'
>): JSX.Element {
  return <StyledProductCard {...props}>{children}</StyledProductCard>;
}

ProductCardView.MediaContainer = MediaContainer;
ProductCardView.Content = Content;
ProductCardView.AddToWishlistButton = AddToWishlistButton;
ProductCardView.Vendor = Vendor;
ProductCardView.Title = Title;
ProductCardView.Price = Price;

export default ProductCardView;
